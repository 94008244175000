import DrawerDashboard from "@component/DrawerDashboard";
import { AuthContext } from "@context/AuthContext";
import { withConfig } from "@context/ConfigContext";
import useAuth from "@hook/useAuth";
import useFetch from "@hook/useFetch";
import GuestTokenProvider from "@component/GuestTokenProvider";

function Root({ config }) {

    const { auth, error } = useAuth(config.auth);

    return (
        <AuthContext.Provider value={auth}>
            {auth && <GuestTokenProvider>
                <DrawerDashboard />
            </GuestTokenProvider>}
            {error && <div>{error}</div>}
        </AuthContext.Provider>
    );
}

export default withConfig(Root);