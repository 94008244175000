import React from 'react';

export const AuthContext = React.createContext(undefined);

export function withAuth(Component) {
    return function AuthenticatedComponent(props) {
        return (
            <AuthContext.Consumer>
                {auth => <Component auth={auth} {...props} />}
            </AuthContext.Consumer>
        );
    };
}