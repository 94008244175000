import { useEffect, useState } from "react"

export default function useFetch(url, settings={}) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        console.log("requesting", url, settings);
        
        fetch(url, settings)
            .then(blob => blob.json())
            .then(data => setData(data))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }, [url])

    return { data, error, loading }
}