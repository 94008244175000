import { withAuth } from "@context/AuthContext"
import { withConfig } from "@context/ConfigContext"
import { GuestTokenContext } from "@context/GuestTokenContext"
import useFetch from "@hook/useFetch";


function parseClause(clause, claims) {
    if ( clause.includes("$email") ){
        clause = clause.replace("$email", claims.email)
    }

    return clause
}

function GuestTokenProvider({ auth, config, children }) {
    const { data } = useFetch(`${config.api}/v1/private/analytics/guest_token`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${auth.token}`
        },
        body: JSON.stringify({
            resources: config.superset.dashboards.map(d => d.embed.id),
            rls: config.superset.rls.map(clause => parseClause(clause, auth.tokenParsed))
        })
    })

    console.log(data);

    return (<>
        {data && <GuestTokenContext.Provider value={data.token}>
            {children}
        </GuestTokenContext.Provider>}
    </>);

}

export default withConfig(withAuth(GuestTokenProvider))