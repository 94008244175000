import React from 'react';

export const ConfigContext = React.createContext(undefined);

export function withConfig(Component) {
    return function ConfiguredComponent(props) {
        return (
            <ConfigContext.Consumer>
                {config => <Component config={config} {...props} />}
            </ConfigContext.Consumer>
        );
    };
}