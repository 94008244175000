import { Drawer, List, ListItem, ListItemText, Typography, Button } from "@mui/material/index";
import image from "@asset/museiitaliani.png"
import "@style/DrawerDashboard.css"
import { withAuth } from "@context/AuthContext";
import { withConfig } from "@context/ConfigContext";
import { useEffect, useRef, useState } from "react";
import { embedDashboard, SupersetClient } from "@superset-ui/embedded-sdk";
import { withGuestToken } from "@context/GuestTokenContext";

const drawerStyle = {
    width: 240,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: 240,
        backgroundColor: '#2c4a99',
        color: '#ffffff',
    },
}

const brandStyle = { padding: 16, textAlign: 'center' }
const imgStyle = { width: 200, height: 100 }

function DrawerBrand() {
    return <div style={brandStyle}>
        <img src={image} alt="musei italiani" style={imgStyle} />
        <Typography variant="h6">SMI ANALISI</Typography>
    </div>
}

function DrawerMenu({ dashboardList, onClick, selectedItem }) {

    return <List>
        {dashboardList.map((item, index) => (
            <ListItem
                button={"true"}
                key={index}
                selected={selectedItem === item}

                onClick={() => onClick(item)}
                sx={{
                    cursor: 'pointer',
                    backgroundColor:
                        selectedItem === item ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                    '&:hover': {
                        backgroundColor: selectedItem === item ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                    },
                }}
            >
                <ListItemText primary={item.context.title} />
            </ListItem>
        ))}
    </List>
}

function DrawerDashboard({ dashboardList, onSelect, username, logoutURL }) {
    const [selectedItem, setSelectedItem] = useState(null);

    function onClick(item) {
        setSelectedItem(item);
        onSelect(item);
    }

    // const [navItems] = useState(navData); // Usa il JSON direttamente come dati
    // var loggedInUser = ''; // Sostituisci con l'utente loggato
    // const [isModalOpen, setIsModalOpen] = useState(false); // Stato per controllare la visibilità della modale


    // const handleOpenModal = () => {//-----------------------------------------
    //     setIsModalOpen(true); // **NUOVO** Mostra la modale
    // };

    // const handleCloseModal = () => {//-----------------------------------------
    //     setIsModalOpen(false); // **NUOVO** Chiudi la modale
    // };


    // const handleLogout = () => {

    //     if (process.env.REACT_APP_REMOVE_TOKEN_ITEM_ON_LOGOUT != "") {
    //         localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_TOKEN_ITEM); // Rimuovi il token di accesso
    //     }

    //     if (process.env.REACT_APP_INVALIDATES_KEYCLOAK_TOKEN != "") {

    //         // **AGGIUNTO** Reindirizza l'utente a un'altra pagina   
    //         if (process.env.REACT_APP_KEYCLOAK_REDIRECT_POST_LOGOUT != "") {
    //             keycloak.logout({
    //                 redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_POST_LOGOUT
    //             });

    //         } else {
    //             keycloak.logout()
    //         }
    //     }

    //     handleCloseModal(); // Chiudi la modale 

    // };

    // const userDetails = keycloak.tokenParsed;

    // if (userDetails != undefined) {
    //     loggedInUser = userDetails.name
    // }

    return (
        <Drawer variant="permanent" sx={drawerStyle} >
            <DrawerBrand />
            <DrawerMenu dashboardList={dashboardList} onClick={onClick} selectedItem={selectedItem} />


            <div style={{ marginTop: 'auto', padding: 16 }}>
                <Typography variant="body2">Utente: {username}</Typography>
                <Button
                    variant="contained"
                    fullWidth
                    style={{
                        backgroundColor: '#ffffff', // Sfondo bianco
                        color: '#2c4a99',          // Testo con colore #2c4a99
                        fontWeight: 'bold',        // Testo in grassetto
                        textTransform: 'none',     // Evita che il testo sia tutto maiuscolo
                    }}
                    href={logoutURL}
                // href={"http://localhost:9090/logout/"}

                >
                    LOGOUT
                </Button>
            </div>


            {/* <Dialog open={isModalOpen} onClose={handleCloseModal}>
                <DialogTitle>{process.env.REACT_APP_DIALOG_TITLE_ON_LOGOUT}</DialogTitle>
                <DialogContent>
                    <Typography>
                        {process.env.REACT_APP_DIALOG_MESSAGE_ON_LOGOUT}
                    </Typography>
                </DialogContent>

                <DialogActions>

                    <Button onClick={handleLogout} color="primary">
                        {process.env.REACT_APP_DIALOG_TEXT_APPROVA}
                    </Button>


                    <Button onClick={handleCloseModal} color="secondary">
                        {process.env.REACT_APP_DIALOG_TEXT_NON_APPROVA}
                    </Button>
                </DialogActions>
            </Dialog> */}


        </Drawer>
    );
}

function DrawerDefault() {
    return <iframe
        src={"/default.html"}
        title="Contenuto Predefinito"
        style={{ width: '100%', height: '100%', border: 'none' }}
    />
}

function EmbeddedDashboard({ base, item, token }) {
    const ref = useRef();
    useEffect(() => {
        embedDashboard({
            mountPoint: ref.current, // any html element that can contain an iframe
            fetchGuestToken: () => token,
            ...base,
            ...item.embed
        });

        return () => { };
    }, [item]);

    return <div ref={ref} className="full" />
}

function Navigator({ config, auth, token }) {
    const dashboardList = config.superset.dashboards;
    const [item, setItem] = useState();

    return <div style={{ display: 'flex', height: '100vh' }}>
        <DrawerDashboard dashboardList={dashboardList} onSelect={setItem} username={auth.tokenParsed.email} logoutURL={auth.createLogoutUrl({ redirectUri: window.location.href })} />
        <div style={{ flex: 1, padding: 0, width: '100%', height: '100%', border: 'none' }}>
            {item === undefined
                ? <DrawerDefault />
                : <EmbeddedDashboard base={config.superset.base} item={item} token={token} />}
        </div>
    </div>

}

export default withConfig(withAuth(withGuestToken(Navigator)));