import { ConfigContext } from "@context/ConfigContext";
import useFetch from "@hook/useFetch";
import Root from '@view/Root';
import '@style/App.css';

function App() {
  const { data  } = useFetch("/config.json");

  return (
    <ConfigContext.Provider value={data}>
      {data && <Root />}
    </ConfigContext.Provider>
  );
}

export default App;
