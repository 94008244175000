import React from 'react';

export const GuestTokenContext = React.createContext(undefined);

export function withGuestToken(Component) {
    return function AuthenticatedComponent(props) {
        return (
            <GuestTokenContext.Consumer>
                {token => <Component token={token} {...props} />}
            </GuestTokenContext.Consumer>
        );
    };
}