import { useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';

// https://www.keycloak.org/securing-apps/javascript-adapter#_api_reference
var KeycloakFactory = (function () {
    var init = false;
    var instance;
    return {
        getInit: function () { return init },
        setInit: function () {
            init = true;
        },
        getInstance: function (config) {
            if (instance == null) {
                instance = new Keycloak(config);
            }

            return instance;
        }
    };
})();

export default function useAuth(config) {
    const [auth, setAuth] = useState(null);
    const [error, setError] = useState("Unauthorized");

    useEffect(() => {
        if (KeycloakFactory.getInit()) return;

        KeycloakFactory.setInit();

        KeycloakFactory.getInstance(config).init({ onLoad: 'login-required', redirectUri: window.location.href })
            .then(authenticated => {
                if (authenticated) {
                    setAuth(KeycloakFactory.getInstance(config));
                    setError(null);
                } else {
                    setError("Not authenticated")
                }
            })
            .catch(err => {
                setError(err);
            });
    }, []);

    return { auth, error };
};

